import React from 'react'
import "./Wrapper.css"

const Wrapper = () => {
  return (
    <>
       <div className='wrapper'>
         <div className='Container wrapper-box'>
          <div className='wrapper-text'>
             <h1>Grow Your Business With<br/> <mark>RT INFOTECH</mark></h1>
             <p>RT INFOTECH has become a widely utilized messaging service
               recently. Its goal is to effectively communicate brand
                information and product notifications to customers in 
                the most informative and engaging way.</p>
          </div>
          <div className='wrapper-img'>
               <img src='https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/Untitled%20design%20(2).png?updatedAt=1716898807844'loading="lazy"/>
          </div>

         </div>
            </div>
    </>
  )
};

export default Wrapper;