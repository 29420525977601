import React from 'react'
import BulkSmsBanner from '../components/BulkSmsBanner';
import BulkSmsStory from '../components/BulkSmsStory';
import BulkSmsCard from '../components/BulkSmsCard';

const BulkSmsPage = () => {
  return (
    <>
      <BulkSmsBanner/>
      <BulkSmsStory/>
      <BulkSmsCard/>
    </>
  )
};

export default BulkSmsPage;