import React from 'react'
import "./ContactMap.css"

const ContactMap = () => {
  return (
    <>
      <div>
        <div className='Container'>
        
       <div className='contact-map'>
       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.297877381421!2d77.38122119847247!3d28.6208331680367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff5d53c9933%3A0x5a9066b8d7b30218!2sRT%20INFOTECH!5e0!3m2!1sen!2sin!4v1716613263503!5m2!1sen!2sin" 
       width="600" height="450"  allowfullscreen loading="lazy" title="map" referrerPolicy=""></iframe>
       </div>

        </div>
      </div>
    </>
  )
}

export default ContactMap;