import React from 'react'
import VoiceCallPage from './pages/VoiceCallPage';

const VoiceCall = () => {
  return (
    <>
      <VoiceCallPage/>
    </>
  )
};

export default VoiceCall;