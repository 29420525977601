import React from 'react'
import './AboutBanner.css'

const AboutBanner = () => {
  return (
    <>
    <div className='about-banner'>About Us</div>
    </>
  )
};

export default AboutBanner;