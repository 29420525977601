import React from "react";

const WhatsappStory = () => {
  return (
    <>
      <div class="bulk-card-main">
        <div class="Container bulk-card-box">
          <div class=" bulk-card">
            <p className="history-text">WhatsApp Marketing</p>
            <p class="bulk-card-text card-p">
              WhatsApp marketing involves using the WhatsApp messaging platform
              to connect with potential customers. Businesses can create
              specialized accounts, send updates and promotions directly to
              customers, utilize broadcast lists to reach multiple contacts at
              once, employ chatbots for automated responses, manage group chats
              for community engagement, share time-limited content through
              WhatsApp Status, and facilitate transactions via WhatsApp Payments
              where available. Personalizing messages based on customer
              preferences, prioritizing data privacy and consent, monitoring
              campaign performance, and adhering to WhatsApp's policies are
              essential for effective and responsible marketing.
            </p>
          </div>
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/10%20(1).webp?updatedAt=1716892147260"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
        <div class="Container bulk-card-box">
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/9%20(1).webp?updatedAt=1716892147280"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>

          <div class=" bulk-card">
            <p className="history-text">
            Here's why our WhatsApp marketing services stand out:
            </p>
            <p class="bulk-card-text card-p">
              1. Tailored Approach: We customize our strategies to fit your
              business goals and target audience, ensuring maximum relevance and
              effectiveness.
              <br />
              2. Personalized Communication: We prioritize personalized
              messaging to enhance customer engagement and build stronger
              relationships.
              <br />
              3. Innovative Solutions: Our team stays ahead of trends, utilizing
              cutting-edge tools and techniques to deliver impactful campaigns.
              <br />
              4. Compliance and Ethics: We adhere strictly to WhatsApp's
              policies and industry regulations, ensuring ethical practices and
              safeguarding user privacy.
              <br />
              5. Dedicated Support: Our experienced professionals are committed
              to providing top-notch support and assistance throughout the
              duration of your campaign.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsappStory;
