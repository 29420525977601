import React from 'react'

const BlogBanner = () => {
  return (
    <>
    <div className='about-banner'>Blog </div>
    </>
  )
};

export default BlogBanner;