import React from "react";

const DigitalStory = () => {
  return (
    <>
       <div class="bulk-card-main">
        <div class="Container bulk-card-box">
          <div class=" bulk-card">
            <p className="history-text">Digital Marketing</p>
            <p class="bulk-card-text card-p">
              Digital advertising offers numerous advantages for businesses. By
              optimizing your website to attract your desired audience and
              employing benchmarking techniques to tailor your digital marketing
              plan, you can drive more traffic and maximize ROI. Our team of
              optimization experts crafts customized strategies based on your
              specific needs, utilizing a multi-layered approach that analyzes
              factors such as your business requirements, budget, target
              audience, and goals. Core components of our strategy include SEO,
              SMO, Content Writing, and Branding, executed by certified
              professionals with extensive experience. With meticulous SEO
              strategies focused on effective keyword research, we ensure your
              website ascends to the top of search engine results, generating
              leads and conversions along the way.
            </p>
          </div>
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/1%20(4).webp?updatedAt=1716892147110"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
        <div class="Container bulk-card-box">
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/2%20(3).webp?updatedAt=1716892147006"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>

          <div class=" bulk-card">
            <p className="history-text">Planning & Strategy</p>
            <p class="bulk-card-text card-p">
              Planning and strategizing in digital marketing encompass a variety
              of online endeavors beyond mere advertising. The key is to center
              these activities around the needs and preferences of your target
              audience to achieve optimal results. Luckily, we boast a dedicated
              team of digital marketing professionals who are tirelessly
              committed to captivating, interacting with, and ultimately
              converting your audience into paying customers.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalStory;
