import React from 'react'
import AboutUsPage from './pages/AboutUsPage';

const AboutUs = () => {
  return (
    <>
    <AboutUsPage/>
        </>
  )
};

export default AboutUs;