import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBullhorn, faRocket, faMagnifyingGlassLocation} from "@fortawesome/free-solid-svg-icons";

const DigitalCard = () => {
  return (
    <>
     <div className='bulksms-card'>
        <div className='Container bulksms-card-main'>
          <div className='bulksms-card-box'>
            <div className='bulksms-card-img'>
            <FontAwesomeIcon icon={faBullhorn} />
            </div>
            <div className='bulksms-card-text'>
            <h4>Ads creative</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faRocket} />
            </div>
          <div className='bulksms-card-text'>
             <h4>Boost your posts with a tap</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faBullhorn} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Audience engagement</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faMagnifyingGlassLocation} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Track your ad performance</h4>
          </div>
          </div>
        </div>
     </div>
    </>
  )
};

export default DigitalCard;