import React from 'react'
import VoiceCallBanner from '../components/VoiceCallBanner';
import VoiceCallStory from '../components/VoiceCallStory';
import VoiceCallCard from '../components/VoiceCallCard';

const VoiceCallPage = () => {
  return (
    <>
      <VoiceCallBanner/>
      <VoiceCallStory/>
      <VoiceCallCard/>
    </>
  )
};

export default VoiceCallPage;