import React from "react";

const VoiceCallStory = () => {
  return (
    <>
      <div class="bulk-card-main">
        <div class="Container bulk-card-box">
          <div class=" bulk-card">
            <p className="history-text">Voice Call</p>
            <p class="bulk-card-text card-p">
              A voice call allows people to talk to each other in real-time over
              a distance, using different technologies. Traditional calls used
              analog signals over copper wires, while digital calls offer better
              quality and extra features. Mobile phones transmit calls via radio
              waves to cell towers, and VoIP technology enables calls over the
              internet. Video calls let you see and hear the other person,
              popular for remote meetings. Modern services offer various
              features like call waiting and conference calling. VoIP often
              provides cheaper international calls, and many services use
              encryption for security. Emergency services can be reached by
              dialing specific numbers, and there are services to help people
              with hearing impairments communicate effectively.
            </p>
          </div>
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/12%20(1).webp?updatedAt=1716892147182"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
        <div class="Container bulk-card-box">
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/11%20(2).webp?updatedAt=1716892147153"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>

          <div class=" bulk-card">
            <p className="history-text">
            What Makes Our Voice Call Services Stand Out?
            </p>
            {/* <h5 class="card-title card-text1">Your Partner for <br/> Software Innovation</h5> */}
            <p class="bulk-card-text card-p">
              1. Introduction Calls: We initiate contact with new prospects to
              introduce your products or services, setting the stage for
              potential engagement.
              <br />
              2. Engagement Calls: We follow up with interested leads to
              cultivate relationships, ensuring sustained interest and
              involvement.
              <br />
              3.Follow-Up Calls: We check in with leads or customers after
              initial contact, maintaining communication and addressing any
              concerns.
              <br />
              4. Appointment Setting Calls: We facilitate the scheduling of
              meetings or consultations with potential customers, streamlining
              the process for both parties.
              <br />
              5. Feedback Calls: We gather valuable insights from customers
              about their experiences, allowing for continuous improvement and
              optimization.
              <br />
              6. Upselling/Cross-Selling Calls: We present additional products
              or services to existing customers, maximizing value and fostering
              loyalty.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoiceCallStory;
