import React from 'react'
import LeadPage from './pages/LeadPage';

const Lead = () => {
  return (
    <>
      <LeadPage/>
    </>
  )
};

export default Lead;