import React from 'react'
import ServiceBanner from '../components/ServiceBanner';
import ServiceSection from '../components/ServiceSection';

const ServicePage = () => {
  return (
    <>
    <ServiceBanner/>
    <ServiceSection/>
    </>
  )
};

export default ServicePage;