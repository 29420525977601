import React from 'react'
import './BulkSmsCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faFileLines, faCommentSms, faUserPlus } from "@fortawesome/free-solid-svg-icons";

const BulkSmsCard = () => {
  return (
    <>
     <div className='bulksms-card'>
        <div className='Container bulksms-card-main'>
          <div className='bulksms-card-box'>
            <div className='bulksms-card-img'>
            <FontAwesomeIcon icon={faCode} />
            </div>
            <div className='bulksms-card-text'>
            <h4> API integration</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faFileLines} />
            </div>
          <div className='bulksms-card-text'>
             <h4>Delivery reports</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faCommentSms} />
            </div>
          <div className='bulksms-card-text'>
            <h4>SMS scheduling</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faUserPlus} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Customer Sender Id</h4>
          </div>
          </div>
        </div>
     </div>
    </>
  )
};

export default BulkSmsCard;