import React from "react";
import "./AboutSection.css";

const AboutSection = () => {
  return (
    <>
      <div className="servicetest">
        <div className="Container">
          <div className="servicetest-main">
            <div className="servicetest-card">
              <div className="profile">
                <img
                  src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/1%20(1).webp?updatedAt=1716448729391"
                  loading="lazy"
                  alt="Mission"
                />
              </div>
              <div>
                <h4>Our Mission</h4>
                <p>
                  Our aim is to support our clients in initiating engaging
                  dialogues through our marketing and messaging platforms. We
                  offer distinct products and services that improve their
                  customers' experiences. We are committed to taking proactive
                  steps to help businesses stand out from their competitors in
                  the industry.
                </p>
              </div>
            </div>

            <div className="servicetest-card">
              <div className="profile">
                <img
                  src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/2.webp?updatedAt=1716448729293"
                  loading="lazy"
                  alt="Vision"
                />
              </div>
              <div>
                <h4>Our Vision</h4>
                <p>
                  Our vision is to lead the way in transformative marketing
                  strategies that redefine industry standards and drive
                  sustainable growth and success for businesses. We foresee a
                  future where our innovative methods and unwavering dedication
                  to excellence set new benchmarks for marketing effectiveness
                  and ROI.
                </p>
              </div>
            </div>

            <div className="servicetest-card">
              <div className="profile">
                <img
                  src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/3.webp?updatedAt=1716448729338"
                  loading="lazy"
                  alt="Objective"
                />
              </div>
              <div>
                <h4>Objective</h4>
                <p>
                  Build a foundation for genuine business success by leveraging
                  the digital art and craft of marketing. Excel in the digital
                  marketing era by establishing high standards of quality
                  through attention to all crucial elements. Above all, strive
                  to be recognized for our ethical approach and commitment to
                  delivering satisfactory services to our customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
