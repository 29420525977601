import React from 'react'
import ServicePage from './pages/ServicePage';

const Service = () => {
  return (
    <>
     <ServicePage/>
    </>
  )
};

export default Service;