import React from 'react'
import WhatsappBanner from '../components/WhatsappBanner';
import WhatsappStory from '../components/WhatsappStory';
import WhatsappCard from '../components/WhatsappCard';

const WhatsappPage = () => {
  return (
    <>
      <WhatsappBanner/>
      <WhatsappStory/>
      <WhatsappCard/>
    </>
  )
};

export default WhatsappPage;