import './App.css';
import Main from './common/pages/Main';

function App() {
  return (
   <>
   <Main/>
   </>
  );
}

export default App;
