import React from 'react'
import ContactUsPage from './pages/ContactUsPage';

const ContactUs = () => {
  return (
    <>
      <ContactUsPage/>
    </>
  )
};

export default ContactUs;