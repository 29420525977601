import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const showInMapClicked = () => {
    window.open(
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.297877381421!2d77.38122119847247!3d28.6208331680367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff5d53c9933%3A0x5a9066b8d7b30218!2sRT%20INFOTECH!5e0!3m2!1sen!2sin!4v1716613263503!5m2!1sen!2sin" +
        28.622152968522244 +
        "," +
        77.38567508240752
    );
  };
  return (
    <>
      <div className="footer-2">
        <div className="Container">
          <div className="footer-upper">
            <div className="upper-first">
              <div className="foot-logo">
                <Link to="/home">
                  <img
                    src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/logo-removebg-preview.png?updatedAt=1716025139394"
                    loading="lazy"
                    alt="logo"
                  />
                </Link>
              </div>
              <h6>RT INFOTECH</h6>
              <p>
                Our designed solutions have been efficiently functioning across
                the globe and into a full-service web solutions company in a
                span of 6 years.
              </p>
            </div>

            <div className="footer-mid-2">
              <h4>LATEST NEWS</h4>
                <div class="news-item">
                <div class="news-title">
                  <Link to="/blog">
                    Mastering Growth: The Dynamics of Lead Generation Services
                  </Link>
                </div>
              </div>
              <div class="news-item">
                <div class="news-title">
                  <Link to="/blog">
                    Mastering Growth: The Dynamics of Lead Generation Services
                  </Link>
                </div>
              </div>
              <div class="news-item">
                <div class="news-title">
                  <Link to="/blog">
                    Mastering Growth: The Dynamics of Lead Generation Services
                  </Link>
                </div>
              </div>
              </div>

              <div className="footer-mid">
              <h4>POPULAR NEWS</h4>
              <div class="news-item">
                <div class="news-title">
                  <Link to="/blog">
                    Mastering Growth: The Dynamics of Lead Generation Services
                  </Link>
                </div>
              </div>
              <div class="news-item">
                <div class="news-title">
                  <Link to="/blog">
                    Mastering Growth: The Dynamics of Lead Generation Services
                  </Link>
                </div>
              </div>
              <div class="news-item">
                <div class="news-title">
                  <Link to="/blog">
                    Mastering Growth: The Dynamics of Lead Generation Services
                  </Link>
                </div>
              </div>
            </div>

            <div className="upper-2nd">
              <h4 >CONTACT US</h4>
              <ul className="upper-info">
                <li className="upper-info1" onClick={showInMapClicked}>
                  <Link to="">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="footer-link-icon1"
                    />
                    Reg Office : E-185, Sector 63, Noida-201301
                  </Link>
                </li>
                {/* <li className="upper-info">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GSTIN/UIN: 09AARCM3596P1Z9
                </li> */}
                <li className="upper-info1">
                  <Link
                    to="javascript:void(0)"
                    onClick={() =>
                      (window.location = "mailto:info@rtinfotech.net.in")
                    }
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="footer-link-icon1"
                    />
                    info@rtinfotech.net.in
                  </Link>
                </li>
                <li className="upper-info1">
                  <Link to="tel:+91 9997028428">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="footer-link-icon1"
                    />
                    +91 9997028428
                  </Link>
                </li>
                <li className="footer-link">
                <Link
              to="https://www.facebook.com/rtinfotech01"
              target="blank"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                className="footer-link-icon"
              />
            </Link>
            <Link
              to="https://www.instagram.com/rtinfotech01/?igsh=OTl5ZWp1cTYyY2Vi"
              target="blank"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="footer-link-icon"
              />
            </Link>
           
            <Link to="https://wa.me/+919997028428" target="blank">
              <FontAwesomeIcon icon={faWhatsapp} className="footer-link-icon" />
            </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
           <div className="Container">
            <h6>Copyright © 2024. All Rights Reserved.</h6>
           </div>
      </div>
    </>
  );
};

export default Footer;
