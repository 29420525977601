import React from "react";

const IvrStory = () => {
  return (
    <>
        <div class="bulk-card-main">
        <div class="Container bulk-card-box">
          <div class=" bulk-card">
            <p className="history-text">IVR</p>
            <p class="bulk-card-text card-p">
              IVR systems automate caller interactions, handling tasks like call
              routing and information collection efficiently. They offer
              tailored menus and self-service options, boosting operational
              efficiency and customer satisfaction. By streamlining processes,
              IVR enhances business performance and reduces costs associated
              with manual handling of calls. These systems are versatile,
              finding applications in industries ranging from telecommunications
              to healthcare. They provide multilingual support and integrate
              seamlessly with existing databases and CRM systems. IVR's ability
              to handle payments securely over the phone adds another layer of
              convenience for customers. Overall, IVR represents a vital tool
              for modern businesses seeking to optimize customer service and
              streamline operations.
            </p>
          </div>
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/6%20(2).webp?updatedAt=1716891798837"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
        <div class="Container bulk-card-box">
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/5%20(2).webp?updatedAt=1716891798980"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>

          <div class=" bulk-card">
            <p className="history-text">
            Streamlining Customer Communication: The Core Principle of IVR Calls
            </p>
            <p class="bulk-card-text card-p">
              The core principle of IVR calls lies in prioritizing user control
              and operational efficiency. Through intelligent computer
              algorithms, this telephonic system ensures smooth interactions
              between executives and customers in real-time. IVR facilitates
              call connections and guides listeners through menu scripts, while
              engineers manage data service requests to automate call processes.
              Touch-tone telephone interactions enhance engagement capabilities,
              making IVR an efficient tool for seamless customer engagement.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default IvrStory;
