const serviceData = [
    {
       imgSrc: "https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/SMS%20SERVICES.webp?updatedAt=1716035827546",
       title: "BULK SMS",
       info: "In recent times, RT INFOTECH has emerged as a leading messaging service provider Our primary goal is to deliver brand information and product notifications to customers in a compelling and informative manner.",
       btn: "Read More",
       link: "/bulk-sms",
    },
    {
       imgSrc:"https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/WHATSAPP%20MARKETING.webp?updatedAt=1716035827729",
       title: "WhatsApp Marketing",
       info: "WhatsApp is a versatile mobile application facilitating communication across various platforms like iPhone, BlackBerry, Android, and Windows Phones. It surpasses traditional SMS in immediacy since a user is less likely to overlook a WhatsApp message.",
       btn: "Read More",
       link: "/whatsapp-marketing",
    },
    {
       imgSrc: "https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/DIGITAL%20MARKETING%20(1).webp?updatedAt=1716035827666",
       title: "Digital Marketing",
       info: "Digital Marketing involves a range of online activities beyond just advertising, all of which must be focused on the end users to achieve successful outcomes.",
       btn: "Read More",
       link: "/digital-marketing",
    },
    {
       imgSrc: "https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/VOICE%20CALL.webp?updatedAt=1716035827666",
       title: "Voice Call",
       info: "Voice Call Marketing is a useful tool to connect millions of customers in short period of time. We can use voice call and pre-recorded voice messages that are sent to mobile phones and landline phones tend to bring in great results.",
       btn: "Read More",
       link: "/voice-call",
    },
    {
       imgSrc: "https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/IVR%20SERVICES.webp?updatedAt=1716035827376",
       title: "IVR Service",
       info: "Interactive Voice Response is a phone system feature that uses recorded messages to interact with callers. Callers can navigate the system using keypad inputs or voice commands to access information or connect to specific departments, all without speaking to a live agent.",
       btn: "Read More",
       link: "/ivr",
    },
    {
       imgSrc: "https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/LEAD%20GENERATION.webp?updatedAt=1716035827717",
       title: "Lead Generation",
       info: "Lead generation is critical for online marketing success, serving as the initial phase in turning visitors into paying customers. We specialize in lead generation services in Noida, offering top-notch assistance in creating high-value leads tailored to your target audience. ",
       btn: "Read More",
       link: "/lead-generation",
    },
  ];

  export default serviceData;
