import {React, useState} from 'react'
import './ContactForm.css'

const ContactForm = () => {
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [company, setCompany] = useState("");
   const [number, setNumber] = useState("");
   const [message, setMessage] = useState("")

   const handleSubmit = (e) => {
      e.preventDefault();
  }
  return (
    <>
      <div class="container Container">
      {/* <div class="text">
         Contact us Form
      </div> */}
      <form  action='Contact.php' method='post' onSubmit={handleSubmit}>
         <div class="form-row">
            <div class="input-data">
               <input type="text" required  name="validation_name"
                value={name}
                onChange={(e) => {setName(e.target.value)}}/>
               <div class="underline"></div>
               <label for="validation-name">First Name</label>
            </div>
            <div class="input-data">
               <input type="text" required name="validation_number"
               value={number}
               onChange={(e) => {setNumber(e.target.value)}}/>
               <div class="underline"></div>
               <label for="validation-number">Number</label>
            </div>
         </div>
         <div class="form-row">
            <div class="input-data">
               <input type="text" required name="validation_email"
               value={email}
                onChange={(e) => {setEmail(e.target.value)}}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"/>
               <div class="underline"></div>
               <label for="validation-email">Email Address</label>
            </div>
            <div class="input-data">
               <input type="text" required name="validation_organization"
               value={company}
               onChange={(e) => {setCompany(e.target.value)}}/>
               <div class="underline"></div>
               <label for="validation-organization">Website Name</label>
            </div>
         </div>
         <div class="form-row">
         <div class="input-data textarea">
            <textarea rows="8" cols="80" require name="validation_message"
             value={message}
             onChange={(e) => {setMessage(e.target.value)}}d></textarea>
            <br />
            <div class="underline"></div>
            <label for="validation-textarea">Write your message</label>
            <br />
            <div class="form-row submit-btn">
               <div class="input-data">
                  <div class="inner"></div>
                  <input type="submit" value="submit"/>
               </div>
            </div>
            </div>
            </div>
      </form>
      </div>
    </>
  )
};

export default ContactForm;