import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHeadphones, faFilePen, faPhoneVolume, faMicrophoneLines} from "@fortawesome/free-solid-svg-icons";


const IvrCard = () => {
  return (
    <>
     <div className='bulksms-card'>
        <div className='Container bulksms-card-main'>
          <div className='bulksms-card-box'>
            <div className='bulksms-card-img'>
            <FontAwesomeIcon icon={faHeadphones} />
            </div>
            <div className='bulksms-card-text'>
            <h4>Interactive voice response</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faMicrophoneLines} />
            </div>
          <div className='bulksms-card-text'>
             <h4>Sound Recording and Reproduction</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faPhoneVolume} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Schedule Calls</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faFilePen} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Analytics and Reporting</h4>
          </div>
          </div>
        </div>
     </div>
    </>
  )
};

export default IvrCard;