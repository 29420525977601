import React from "react";
import "./Blog.css";

const Blog = () => {
  return (
    <>
      <div class="card-main mb-3">
        <div class="Container card-box">
          <div class="col-md-6 blog-card">
            <p className="history-text">About Us</p>
            {/* <h5 class="card-title card-text1">Your Partner for <br/> Software Innovation</h5> */}
            <p class="card-text card-p">
              Welcome to RT Infotech. where digital innovation meets strategic
              excellence. We are a forward-thinking digital marketing agency
              committed to transforming your online presence and driving
              exceptional results. With a blend of creativity, data-driven
              strategies, and cutting-edge technology, we help businesses of all
              sizes thrive in the digital landscape. we believe in a
              collaborative approach. We work closely with our clients to
              understand their goals, challenges, and market dynamics. This
              partnership enables us to craft customized strategies that deliver
              measurable results. Our team of seasoned professionals stays ahead
              of industry trends, ensuring that your business benefits from the
              latest innovations in digital marketing.
            </p>

            <div class="row blog-text-bottom"></div>
          </div>
          <div class="col-md-6 blog-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/SMS%20Services%20(1).webp?updatedAt=1716891118806"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
      
      </div>
    </>
  );
};

export default Blog;
