import React from 'react'

const VoiceCallBanner = () => {
  return (
    <>
    <div className='about-banner'>Voice Call</div>
    </>
  )
};

export default VoiceCallBanner;