import React from 'react'
import WhatsappBanner from '../components/IvrBanner';
import WhatsappStory from '../components/IvrStory';
import WhatsappCard from '../components/IvrCard';
import IvrBanner from '../components/IvrBanner';
import IvrStory from '../components/IvrStory';
import IvrCard from '../components/IvrCard';

const IvrPage = () => {
  return (
    <>
      <IvrBanner/>
      <IvrStory/>
      <IvrCard/>
    </>
  )
};

export default IvrPage;