import React from 'react'
import DigitalPage from './pages/DigitalPage';

const Digital = () => {
  return (
    <>
      <DigitalPage/>
    </>
  )
};

export default Digital;