import React from 'react'
import ContactUsBanner from '../components/ContactUsBanner';
import ContactForm from '../components/ContactForm';
import ContactMap from '../components/ContactMap';

const ContactUsPage = () => {
  return (
    <>
      <ContactUsBanner/>
      <ContactForm/>
      <ContactMap/>
    </>
  )
};

export default ContactUsPage;