import React from 'react'
import LeadBanner from '../components/LeadBanner';
import LeadStory from '../components/LeadStory';
import LeadCard from '../components/LeadCard';

const LeadPage = () => {
  return (
    <>
      <LeadBanner/>
      <LeadStory/>
      <LeadCard/>
    </>
  )
};

export default LeadPage;