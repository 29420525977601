import React from 'react'

const ServiceBanner = () => {
  return (
    <>
    <div className='about-banner'>Services</div>
    </>
  )
};

export default ServiceBanner;