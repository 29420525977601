import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { faPhoneVolume, faPhone, faPhoneSlash, faHeadset } from '@fortawesome/free-solid-svg-icons'

const VoiceCallCard = () => {
  return (
    <>
     <div className='bulksms-card'>
        <div className='Container bulksms-card-main'>
          <div className='bulksms-card-box'>
            <div className='bulksms-card-img'>
            <FontAwesomeIcon icon={faPhoneVolume}/>
            </div>
            <div className='bulksms-card-text'>
            <h4>Outboand Call</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faHeadset} />
            </div>
          <div className='bulksms-card-text'>
             <h4>DTMF</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faPhoneSlash}/>
            </div>
          <div className='bulksms-card-text'>
            <h4>Missed Call</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faPhone}/>
            </div>
          <div className='bulksms-card-text'>
            <h4>Click to Call</h4>
          </div>
          </div>
        </div>
     </div>
    </>
  )
};

export default VoiceCallCard;