import React from "react";
import "./Service.css";
import { Link } from "react-router-dom";
import serviceData from "../data/ServiceData";

const Service = () => {
  return (
    <>
      <div className="cardscroll-box">
        <div className="Container">
          <div className="service-text">
          <h1 className="service-title ">SERVICES WE'RE OFFERING</h1>
          <h5 className="service-subtitle">
            High quality products and services that we stand behind
          </h5>
          </div>
          <div className="cardscroll-box2">
            <section class="articles">
            {serviceData.map((value) => {
              return (
                <>
                <article>
                 <div id="in">

                <div class="article-wrapper">
                  <figure>
                    <img
                      src={value.imgSrc}
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>{value.title}</h2>
                    <p>
                     {value.info}
                    </p>
                  </div>
                </div>
                 </div>
                   
            </article>
                </>
              );
            })}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
