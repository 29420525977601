import React, { useState, useEffect } from "react";
import "./Header.css";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
// import DropDownHover from '../components/home/DropDownHover';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>RT INFOTECH</title>
      </Helmet>
      <div className="nav-main">
        <div class=" header">
          <div className="Container">
            <ul class=" header-end">
              <li class="  header-li">
                <Link
                  class="nav-link active icon"
                  aria-current="page"
                  to="tel:+91-9997028428"
                >
                  <FontAwesomeIcon icon={faPhone} /> +91-99970-28428
                </Link>
              </li>
              <li class=" header-li">
                <Link
                  aria-current="page"
                  to="javascript:void(0)"
                  onClick={() =>
                    (window.location =
                      "mailto: info@rtinfotech.net.in")
                  }
                >
                  <FontAwesomeIcon icon={faEnvelope} className="header-email" />
                  info@rtinfotech.net.in
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="Container main-nav">
          <Link to="/Home">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/logo-removebg-preview.png?updatedAt=1716025139394"
              loading="lazy"
              className="nav-logo"
              alt="logo"
            />
          </Link>
          <ul
            className={isOpen ? "nav-links-mobile" : "nav-links"}
            onClick={() => setIsOpen(false)}
          >
            <li className="nav-li">
              {" "}
              <NavLink to="./Home" active className="nav-home">
                Home
              </NavLink>
            </li>

            <li className="nav-li">
              <NavLink to="./About-Us" className="nav-home">
                About Us
              </NavLink>
            </li>

            <li className="dropdowns nav__menu-item nav-li">
              <NavLink to="./Service" className="nav-home">
                Services
              </NavLink>
              {/* <DropDownHover/> */}
            </li>

            <li className="nav-li">
              <NavLink to="./Blog" className="nav-home">
                Blog
              </NavLink>
            </li>

            <li className="nav-li">
              <NavLink to="./Contact-Us" className="nav-home">
                Contact Us
              </NavLink>
            </li>
            <li className="nav-li">
              <NavLink to="./Home"></NavLink>
            </li>
          </ul>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
            )}
          </button>
        </div>
      </div>

      <div className="whatsapp-chat">
        <Link to="https://wa.me/+919997028428" target="blank">
          <img
            src="https://ik.imagekit.io/xjjzkwxra/Assets/whatsapp.webp?updatedAt=1714457723092"
            loading="lazy"
            alt="whatsapp"
          />
        </Link>
      </div>
    </>
  );
};

export default Header;
