import React from "react";
import "./BulkSmsStory.css";

const BulkSmsStory = () => {
  return (
    <>
      <div class="bulk-card-main">
        <div class="Container bulk-card-box">
          <div class=" bulk-card">
            <p className="history-text">Bulk SMS</p>
            <p class="bulk-card-text card-p">
              RT INFOTECH is a top-tier and dependable Bulk SMS service provider
              based in Noida. We offer a range of SMS services, including
              Transactional Bulk SMS, Promotional Bulk SMS, OTP SMS, and voice
              SMS. Our SMS service is recognized for its high standards,
              cost-effectiveness, and quality, ensuring 100% timely delivery
              reports for all SMS messages. As an authorized Bulk SMS provider
              by the Telecom Regulatory Authority of India (TRAI), our SMPP
              servers are directly connected with major carriers like Vodafone,
              Videocon, and Airtel SMPP-SMS gateways.
            </p>
          </div>
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/Untitled%20design%20(6).jpg?updatedAt=1716896751356"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
        <div class="Container bulk-card-box">
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/Untitled%20design%20(5).jpg?updatedAt=1716896751399"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>

          <div class=" bulk-card">
            <p className="history-text ">
              What Sets Our Bulk Messaging Services Apart?
            </p>
            <p class="bulk-card-text card-p">
              1. High Standards: Reliable, cost-effective, and efficient.
              <br />
              2. Guaranteed Delivery: 100% real-time delivery reports.
              <br />
              3. Regulatory Compliance: Authorized by TRAI.
              <br />
              4. Direct Carrier Connections: Linked with Vodafone, Videocon, and
              Airtel for fast delivery.
              <br />
              5. Cost-Effective: Competitive pricing without quality compromise.
              <br />
              6. Enhanced Engagement: Use discounts, coupons, and surveys in
              campaigns.
              <br />
              7. Global Reach: Market your brand worldwide.
              <br />
              8. Easy Integration: User-friendly platform compatible with
              existing systems.
              <br />
              9. Customer Support: Dedicated assistance for a smooth experience.
              <br />
              10. Diverse Services: Transactional, Promotional, OTP, and Voice
              SMS options.
              <br />
              Partner with MarketBuzzer TeleServices for comprehensive and
              effective bulk messaging solutions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkSmsStory;
