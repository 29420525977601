import React from 'react'

const IvrBanner = () => {
  return (
    <>
    <div className='about-banner'>IVR Service</div>
    </>
  )
};

export default IvrBanner;