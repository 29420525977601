import React from 'react'
import BlogPage from './pages/BlogPage';

const Blog = () => {
  return (
    <>
     <BlogPage/>
    </>
  )
};

export default Blog;