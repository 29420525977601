import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserCheck, faUserXmark, faShieldHalved, faUserTie} from "@fortawesome/free-solid-svg-icons";


const WhatsappCard = () => {
  return (
    <>
     <div className='bulksms-card'>
        <div className='Container bulksms-card-main'>
          <div className='bulksms-card-box'>
            <div className='bulksms-card-img'>
            <FontAwesomeIcon icon={faUserCheck} />
            </div>
            <div className='bulksms-card-text'>
            <h4>Verified Business Accounts</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faUserXmark} />
            </div>
          <div className='bulksms-card-text'>
             <h4>Unverified Business Accounts</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faShieldHalved} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Brand Protection</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faUserTie} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Professional Appearance</h4>
          </div>
          </div>
        </div>
     </div>
    </>
  )
};

export default WhatsappCard;