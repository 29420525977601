import React from "react";
import "./Testimonial.css";

const Testimonial = () => {
  return (
    <div className="monial-box">
      <div className="Container">
        <h1 className="monial-title ">Testimonial</h1>
        <h3 className="monial-subtitle">Our Happy Clients Tell About Us</h3>
        <div
          id="carouselExampleAutoplaying"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="d-block w-80 ">
                <div className="monial-card">
                  <p>
                    We have been using RT Infotech's Bulk SMS services for over
                    a year now, and the impact on our business has been
                    phenomenal. Their platform is incredibly user-friendly,
                    allowing us to send personalized messages to our customers
                    effortlessly. The delivery rates are exceptional, and we
                    have seen a significant increase in customer engagement and
                    response rates.
                  </p>
                  <div className="monial-card-img">
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/6%20(1).webp?updatedAt=1716890661816"
                      alt="client"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-80">
                <div className="monial-card">
                  <p>
                    Rt Infotech's expertise in Facebook campaigns has been a
                    game-changer for us. Their targeted approach and creative ad
                    designs significantly increased our reach and engagement.
                    The results were impressive, with a noticeable boost in
                    leads and conversions. The team's professionalism and
                    strategic insights made the entire process seamless.
                    Highly recommended
                  </p>
                  <div className="monial-card-img">
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/3%20(2).webp?updatedAt=1716890661694"
                      alt="client"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="d-block w-80">
                <div className="monial-card">
                  <p>
                    Using RT Infotech's WhatsApp marketing services has
                    transformed our customer engagement. Their platform is
                    intuitive, and the personalized messaging has significantly
                    boosted our response rates. The support team is exceptional,
                    always available to help us optimize our campaigns. We've
                    seen a notable increase in customer satisfaction and sales.
                    Highly recommend their services
                  </p>
                  <div className="monial-card-img">
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/4%20(1).webp?updatedAt=1716890661782"
                      alt="client"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
