import React from 'react'
import BulkSmsPage from './pages/BulkSmsPage';

const BulkSms = () => {
  return (
    <>
      <BulkSmsPage/>
    </>
  )
};

export default BulkSms;