import React from 'react'

const WhatsappBanner = () => {
  return (
    <>
    <div className='about-banner'>Whatsapp Marketing</div>
    </>
  )
};

export default WhatsappBanner;