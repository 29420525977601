import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './common/components/home/ScrollToTop';
import GoToTop from './common/components/home/GoToTop';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './common/pages/Header';
import Footer from './common/pages/Footer';
import AboutUs from './aboutus';
import Service from './services';
import BulkSms from './bulksms';
import ContactUs from './contact';
import Whatsapp from './whatsapp';
import Ivr from './ivr';
import VoiceCall from './voicecall';
import Digital from './digital';
import Lead from './lead';
import Blog from './blog';
import Error from './error/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
   <ScrollToTop />
   <Header/>
     <Routes>
       <Route path='/'  element={<App/>}/>
       <Route path='/Home'  element={<App/>}/>
       <Route path='/About-Us'  element={<AboutUs/>}/>
       <Route path='/Service'  element={<Service/>}/>
       <Route path='/Blog'  element={<Blog/>}/>
       <Route path='/Contact-Us'  element={<ContactUs/>}/>
       <Route path='/Bulk-SMS'  element={<BulkSms/>}/>
       <Route path='/Whatsapp-Marketing'  element={<Whatsapp/>}/>
       <Route path='/Ivr-Service'  element={<Ivr/>}/>
       <Route path='/Voice-Call'  element={<VoiceCall/>}/>
       <Route path='/Digital-Marketing'  element={<Digital/>}/>
       <Route path='/Lead-Generation'  element={<Lead/>}/>
       <Route  path='*' element={<Error/>}/>
     </Routes>
    
 
   <GoToTop/>
   <Footer/>
   </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
