import React from 'react'

const DigitalBanner = () => {
  return (
    <>
    <div className='about-banner'>Digital Marketing</div>
    </>
  )
};

export default DigitalBanner;