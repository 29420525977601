import {React, useState, useEffect} from 'react'
import "./GoToTop.css"

const GoToTop = () => {
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {

      if(window.scrollY > 100){
        setScrollTop(true);
      }else {
        setScrollTop(false);
      }
    })
  
  }, [])
  
    
    const goToBtn = () =>{
        window.scrollTo( 0, 0, );
    }
    return (
    <>
    <div className='top-btn' onClick={goToBtn}>
    {scrollTop && (
    <img src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/Untitled%20design%20(4).jpg?updatedAt=1716896751404" loading="lazy" alt='arrowUp'/>
  )}
</div>
    </>
  )
};


export default GoToTop;