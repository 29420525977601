import React from 'react'
import BlogBanner from '../components/BlogBanner';
import BlogSection from '../components/BlogSection';

const BlogPage = () => {
  return (
    <>
      <BlogBanner/>
      <BlogSection/>
    </>
  )
};

export default BlogPage;