import React from 'react'

const LeadBanner = () => {
  return (
    <>
    <div className='about-banner'>Lead Generation</div>
    </>
  )
};

export default LeadBanner;