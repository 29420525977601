import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Client.css";

function Client() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          speed: 5000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="client-main">
      <div className="Container">
        <div className="client-header">
          <h1>OUR CLIENTS</h1>
          <h5>PLEASURE TO WORK WITH US</h5>
        </div>

        <div className="slider-container">
          <Slider {...settings}>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/6%20(1).webp?updatedAt=1716890661816"
                loading="lazy"
                className="slider-img"
                alt="divana"
              ></img>
            </div>
            
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/4%20(1).webp?updatedAt=1716890661782"
                loading="lazy"
                className="slider-img"
                alt="ccsu"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/1%20(3).webp?updatedAt=1716890661764"
                loading="lazy"
                className="slider-img"
                alt="godrej"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/5%20(1).webp?updatedAt=1716890661745"
                loading="lazy"
                className="slider-img"
                alt="propshop"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/3%20(2).webp?updatedAt=1716890661694"
                loading="lazy"
                className="slider-img"
                alt="lodha"
              ></img>
            </div>
        
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/Assets/anupam%20Properties.webp?updatedAt=1714626462919"
                loading="lazy"
                className="slider-img"
                alt="anupamProperties"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/2%20(2).webp?updatedAt=1716890661835"
                loading="lazy"
                className="slider-img"
                alt="bonito"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/Assets/Raunak%20Group.webp?updatedAt=1714626462993"
                loading="lazy"
                className="slider-img"
                alt="RaunakGroup"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/Assets/valueleaf.webp?updatedAt=1714626462934"
                loading="lazy"
                className="slider-img"
                alt="valueleaf"
              ></img>
            </div>

            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/Assets/Idelta.webp?updatedAt=1714626462885"
                loading="lazy"
                className="slider-img"
                alt="Idelta"
              ></img>
            </div>
            <div className="slider-div">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/Assets/360%20Realtors.webp?updatedAt=1714626434678"
                loading="lazy"
                className="slider-img"
                alt="Realtorsueleaf"
              ></img>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Client;
