import { React, useState } from "react";
import "./BlogSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SSData from "./data/SSData";

const BlogSection = () => {
  const paraGraph = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: '-webkit-box',
  }
  const [isOpen, setIsOpen] = useState(SSData.map(() => false));
  const [item, setItem] = useState(SSData);


  const menuItem = [...new Set(SSData.map((value) => value.catagory))];

  const categoryItem = (catagory) => {
    const newItem = SSData.filter((newvalue) => newvalue.catagory === catagory);
    setItem(newItem);
  };


  const toggleIsOpen = (index) => {
    const newIsOpenArray = [...isOpen];
    newIsOpenArray[index] = !newIsOpenArray[index];
    setIsOpen(newIsOpenArray);
  };
  return (
    <>
      <div className="blog-section">
        <div className="Container blog-section-box">
          <div className=" blog-section-part1">
            {item.map((value, index) => {
              return (
                <>
                  <div className="blog-section-card">
                    <div className="blog-section-img">
                      <img src={value.ssImg} alt="blog-image" />
                    </div>
                    <div className="blog-section-date">September 13, 2023</div>
                    <div className="blog-section-text">
                      <h4>{value.ssTitle}</h4>
                      <div 
                      style={isOpen[index] ? null : paraGraph}>
                       {value.ssInfo}
                      </div>
                      <div className="blog-section-line"></div>
                   
                        <div className="blog-section-btn" onClick={() => toggleIsOpen(index)}>
                        {isOpen[index] ? "Read Less" : "Read More"}
                          <FontAwesomeIcon
                            icon={faAnglesRight}
                            className="blog-section-btn-icon"
                          />
                        </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="blog-section-part2">
          <div className="blog-section-part2-box">
              <h4>LATEST NEWS</h4>
              <div className="blog-badge" onClick={() => 
                setItem(SSData)}>All</div>
              {menuItem.map((value) => {
                return (
                  <>
                <div class="news-item">
                <div class="news-title"
                 onClick={() => categoryItem(value)}>
                {value}
                </div>
              </div>
                   
                  </>
                );
              })}
            </div> 
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSection;
