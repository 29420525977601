import React from 'react'
import AboutBanner from '../components/AboutBanner';
import AboutStory from '../components/AboutStory';
import AboutSection from '../components/AboutSection';

const AboutUsPage = () => {
  return (
    <>
      <AboutBanner/>
      <AboutStory/>
      <AboutSection/>
    </>
  )
};

export default AboutUsPage;