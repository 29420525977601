import React from 'react'
import WhatsappPage from './pages/WhatsappPage';

const Whatsapp = () => {
  return (
    <>
      <WhatsappPage/>
    </>
  )
};

export default Whatsapp;