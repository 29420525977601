import React from 'react'
import DigitalBanner from '../components/DigitalBanner';
import DigitalStory from '../components/DigitalStory';
import DigitalCard from '../components/DigitalCard';

const DigitalPage = () => {
  return (
    <>
      <DigitalBanner/>
      <DigitalStory/>
      <DigitalCard/>
    </>
  )
};

export default DigitalPage;