import React from "react";
import "./ServiceSection.css";
import { Link } from "react-router-dom";

const ServiceSection = () => {
  return (
    <>
      <section class="cards-wrapper">
        <div class="card-grid-space">
          <a class="card card1" href="#">
            <div className="service-section-text-card">
              <h1>Bulk SMS</h1>
              <p>
                RT INFOTECH is a top-tier and dependable Bulk SMS service
                provider based in Noida. We offer a range...
              </p>
              <div class="tags">
                <Link to="/Bulk-SMS">
                  <div class="tag">Read More</div>
                </Link>
              </div>
            </div>
          </a>
        </div>
        <div class="card-grid-space">
          <a class="card card2" href="#">
            <div className="service-section-text-card">
              <h1>Whatsapp Marketing</h1>
              <p>
                WhatsApp marketing involves using the WhatsApp messaging
                platform to connect with potential customers...
              </p>
              <div class="tags">
                <Link to="/Whatsapp-Marketing">
                  <div class="tag">Read More</div>
                </Link>
              </div>
            </div>
          </a>
        </div>
        <div class="card-grid-space">
          <a class="card card3" href="#">
            <div className="service-section-text-card">
              <h1>Digital Marketing</h1>
              <p>
                Digital advertising offers numerous advantages for businesses.
                By optimizing your website to attract...
              </p>
              <div class="tags">
                <Link to="/Digital-Marketing">
                  <div class="tag">Read More</div>
                </Link>
              </div>
            </div>
          </a>
        </div>
        <div class="card-grid-space">
          <a class="card card4" href="#">
            <div className="service-section-text-card">
              <h1>IVR Service</h1>
              <p>
                IVR systems automate caller interactions, handling tasks like
                call routing and information...
              </p>
              <div class="tags">
                <Link to="/Ivr-Service">
                  <div class="tag">Read More</div>
                </Link>
              </div>
            </div>
          </a>
        </div>
        <div class="card-grid-space">
          <a class="card card5" href="#">
            <div className="service-section-text-card">
              <h1>Voice Call</h1>
              <p>
                A voice call allows people to talk to each other in real-time
                over a distance, using different technologies...
              </p>
              <div class="tags">
                <Link to="/Voice-Call">
                  <div class="tag">Read More</div>
                </Link>
              </div>
            </div>
          </a>
        </div>
        <div class="card-grid-space">
          <a class="card card6" href="#">
            <div className="service-section-text-card">
              <h1>Lead Generation</h1>
              <p>
                Effective lead generation is the cornerstone of successful
                online marketing, marking the first...
              </p>
              <div class="tags">
                <Link to="/Lead-Generation">
                  <div class="tag">Read More</div>
                </Link>
              </div>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default ServiceSection;
