import React from "react";

const LeadStory = () => {
  return (
    <>
  <div class="bulk-card-main">
        <div class="Container bulk-card-box">
          <div class=" bulk-card">
            <p className="history-text">Lead Generation</p>
            <p class="bulk-card-text card-p">
              Effective lead generation is the cornerstone of successful online
              marketing, marking the first step in transforming visitors into
              loyal customers. In Noida, we excel in lead generation services,
              crafting valuable leads customized to your specific target
              audience. With a focus on quality over quantity, our team is
              dedicated to providing outstanding assistance and delivering
              exceptional results. Don't overlook the opportunity to convert
              potential prospects into loyal clientele—get in touch with us
              today to discover how our lead generation solutions can propel
              your business forward!
            </p>
          </div>
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/3%20(3).webp?updatedAt=1716891798900"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>
        </div>
        <div class="Container bulk-card-box">
          <div class=" bulksms-card1">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/4%20(2).webp?updatedAt=1716892147181"
              loading="lazy"
              alt="Image Alt"
              effect="blur"
              class=" rounded-start"
            />
          </div>

          <div class=" bulk-card">
            <p className="history-text">
            Unveiling the Dominance of Text Message Lead Generation: The Ultimate Marketing Weapon
            </p>
            <p class="bulk-card-text card-p">
              At RT INFOTECH , we provide a simplified method for lead
              generation using text messages. Short and direct SMS messages
              resonate more effectively with the target audience than lengthy
              emails, resulting in increased engagement rates. With a
              click-through rate exceeding 21%, SMS marketing offers remarkable
              ROI with minimal infrastructure requirements. By leveraging
              SMS-generating software, businesses can seamlessly execute
              cost-effective SMS campaigns, ensuring guaranteed engagement.
              Contact us today for effortless SMS marketing solutions at
              competitive prices.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadStory;
