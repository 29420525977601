import React from 'react'
import IvrPage from './pages/IvrPage';

const Ivr = () => {
  return (
    <>
      <IvrPage/>
    </>
  )
};

export default Ivr;