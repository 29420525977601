import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faRss, faLocationDot, faShieldHalved} from "@fortawesome/free-solid-svg-icons";


const LeadCard = () => {
  return (
    <>
     <div className='bulksms-card'>
        <div className='Container bulksms-card-main'>
          <div className='bulksms-card-box'>
            <div className='bulksms-card-img'>
            <FontAwesomeIcon icon={faRss} />
            </div>
            <div className='bulksms-card-text'>
            <h4>Rich media</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faLocationDot} />
            </div>
          <div className='bulksms-card-text'>
             <h4>Location sharing</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faShieldHalved} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Encryption</h4>
          </div>
          </div>
          <div className='bulksms-card-box'>
          <div className='bulksms-card-img'>
          <FontAwesomeIcon icon={faCommentDots} />
            </div>
          <div className='bulksms-card-text'>
            <h4>Branded messages</h4>
          </div>
          </div>
        </div>
     </div>
    </>
  )
};

export default LeadCard;